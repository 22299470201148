import '../css/home.css';
import '../css/placeholder.css';

export default () => {
    return (
        <>

            <div className="areaWork">

            </div>

            <script src="../src/js/serviceWorker.js"></script>
        </>
    );
}